import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import { StyledWrapper } from "../../components/wrapper"

const Banner = styled.div`
  background-color: ${props => props.theme.colors.liberty};
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    text-align: center;
  }
`

const Wrapper = styled(StyledWrapper)`
  padding: 48px 16px !important;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 32px;
  }

  p,
  li {
    line-height: 32px;
  }
`

const title = "Términos y Condiciones"

export default () => {
  useEffect(() => {
    window.location.href = "https://syntage.com/legal/terminos-y-condiciones"
  }, [])

  return (
    <Layout title={title}>
      <div style={{ display: "none" }}>
        <Banner>
          <h1>{title}</h1>
        </Banner>
        <Wrapper>
          <p>Fecha de entrada en vigor: 14 de abril de 2021</p>
          <p>
            POR FAVOR LEE CON ATENCIÓN LOS PRESENTES TÉRMINOS Y CONDICIONES (los
            “<strong>T&C’s</strong>”) ANTES DE USAR NUESTRA PLATAFORMA
          </p>
          <p>
            <strong>Prevención legal</strong>. Estos T&C’s son obligatorios
            entre la persona (el “<strong>Usuario</strong>”) que utiliza la
            Plataforma (según se define adelante), de conformidad con el alcance
            de este instrumento legal; y, SATWS TECHNOLOGIES, S.A.P.I. DE C.V.
            (“
            <strong>Nosotros</strong>” o “<strong>SATWS</strong>”). Por lo
            tanto, este es un contrato vinculante y constituye el acuerdo total
            entre las Partes (el Usuario y SATWS referidos conjuntamente) para
            el uso del sitio web, aplicaciones y herramientas digitales,
            propiedad de SATWS (la “<strong>Plataforma</strong>”).
          </p>
          <p>
            <strong>
              Al ingresar, hacer uso de la Plataforma y/o crear un perfil en la
              Plataforma, el Usuario Manifiesta su consentimiento a estos T&C’s.
            </strong>
          </p>
          <h2>Declaraciones y términos generales</h2>
          <p>
            Para hacer uso de la Plataforma y obligarse de conformidad con los
            presentes Términos y Condiciones, el Usuario debe ser mayor de edad,
            en ninguna circunstancia, permitimos el acceso y/o utilización de la
            Plataforma a menores de 18 años. Asimismo, al hacer uso de la
            Plataforma en representación de una persona moral, el Usuario
            manifiesta que cuenta con las facultades necesarias y suficientes
            para obligar a su representada en los términos de estos T&C’s.
          </p>
          <p>
            Por nuestra parte, declaramos: (i) SATWS es una entidad legal
            debidamente constituida conforme las leyes de los Estados Unidos
            Mexicanos, (ii) SATWS se encuentra al día en el cumplimiento de sus
            obligaciones fiscales.
          </p>
          <p>
            Cualquier incumplimiento a estos Términos y Condiciones nos faculta
            para dar por terminada cualquier relación con el Usuario sin
            necesidad de requerimiento judicial.
          </p>
          <h2>Comprobación de datos</h2>
          <p>
            Al ingresar, usar y/o crear un perfil en la Plataforma, el Usuario
            acepta que SATWS puede verificar, por sí o por terceros, en
            cualquier momento los datos que nos proporcione. Para el caso en que
            la información proporcionada para crear un Perfil (según se define
            más adelante) sea falsa o inexacta, SATWS podrá negar acceso a la
            Plataforma, a su entera discreción, así como eliminar cualquier
            contenido relacionado con el Usuario. El Usuario acepta la
            verificación de sus datos, ya sea por SATWS o a través de un tercero
            que nosotros designemos.
          </p>
          <h2>Modificaciones y actualizaciones</h2>
          <p>
            Nos reservamos el derecho de: (i) adicionar o modificar estos T&C’s
            en cualquier momento, así como (ii) ofertar nuevos servicios a
            través de la Plataforma. Por favor permanece pendiente de los
            presentes Términos y Condiciones.
          </p>
          <p>
            Cualquier modificación que realicemos a estos T&C’s aplicarán a
            cualquier sitio web, aplicación y/o cualquier otra herramienta o
            tecnología de la cual SATWS sea titular. Asimismo, dichas
            modificaciones surtirán efectos en el momento en el que estos T&C’s
            sean actualizados.
          </p>
          <p>
            No obstante, lo anterior, al momento de realizar modificaciones o
            actualizaciones a las que se refiere el presente párrafo
            notificaremos a los Usuarios la última fecha de actualización a
            través de la Plataforma o vía correo electrónico sobre
            modificaciones y actualizaciones.
          </p>
          <h2>Privacidad y protección de datos personales</h2>
          <p>
            Los datos personales que de los Usuarios de la Plataforma sean
            recabados por SATWS, serán tratados de conformidad con lo
            establecido en el Aviso de Privacidad que se encuentra contenido en
            el sitio web, aplicaciones móviles, herramientas digitales y
            oficinas de SATWS.
          </p>
          <p>
            Para obtener información correspondiente a la recaudación, registro,
            conservación, modificación, consulta, utilización, comunicación por
            transferencia, difusión o cualquier otro tipo de habilitación de
            acceso, cotejo o interconexión, limitación, supresión o destrucción
            de tus datos personales, deberás consultar nuestro{" "}
            <Link to="/legal/aviso-de-privacidad">Aviso de Privacidad</Link>.
          </p>
          <p>
            Si el Usuario, a través de la Plataforma, llegara a proporcionar
            datos personales de terceros; entonces, el Usuario manifiesta
            expresamente que cuenta con las debidas autorizaciones y ha dado a
            conocer a sus clientes/usuarios el aviso de conformidad
            correspondiente conforme a la Ley de Protección de Datos Personales
            en Posesión de Particulares y su Reglamento (la “
            <strong>Ley</strong>”). En este sentido, SATWS no recaba datos
            personales de los clientes/usuarios de los Usuarios; por lo que, en
            caso de que el Usuario proporcionara dichos datos, SATWS será
            considerado como (“<strong>Encargado</strong>” según se define en la
            Ley) y el Usuario como (“<strong>Responsable</strong>” según se
            define en la Ley).
          </p>
          <p>
            Si el Usuario transfiriera datos personales de sus clientes/usuarios
            a SATWS, éste se obliga con SATWS a poner a disposición de sus
            usuarios/clientes el aviso de privacidad correspondiente, en donde
            se detallen las transferencias y finalidades de las mismas conforme
            a lo dispuesto en la Ley.
          </p>
          <h2>Condiciones generales</h2>
          <h3>a. Creación de Cuenta de Usuario</h3>
          <p>
            A través de la Plataforma, los Usuarios pueden crear una cuenta (la
            “<strong>Cuenta de Usuario</strong>”), a través de la cual, podrán
            conectar sus sistemas con información del Servicio de Administración
            Tributaria (el “<strong>SAT</strong>”) (i) utilizando Interfaces de
            Programación de Aplicaciones (“<strong>APIs</strong>”, por sus
            siglas en inglés), o (ii) a través de nuestra aplicación web (el “
            <strong>Dashboard</strong>”); lo anterior, con la finalidad
            contratar nuestros servicios los cuales están diseñados para
            optimizar:
            <ol type="a">
              <li>finanzas personales y empresariales, </li>
              <li>realizar análisis de riesgo crediticio, </li>
              <li>desarrollar soluciones de tecnología financiera, y</li>
              <li>servicios contables.</li>
            </ol>
          </p>
          <p>
            Para crear una Cuenta de Usuario, el Usuario deberá ingresar a la
            siguiente liga <a href="https://sat.ws">https://sat.ws</a>,
            seleccionar la opción "Crear Cuenta" y proporcionar los datos
            solicitados en nuestro formulario automatizado, los cuales podrán
            ser verificados por SATWS de conformidad con la sección "
            <strong>COMPROBACIÓN DE DATOS</strong>".
          </p>
          <h3>b. Nuestros servicios</h3>
          <p>
            Al crear una Cuenta de Usuario, el Usuario podrá contratar nuestros
            servicios (en adelante los “<strong>Servicios</strong>”):
            <ol type="I">
              <li>
                <strong>Connect</strong>. Permite acceder a la información
                fiscal actualizada de los Usuarios y/o los clientes de los
                Usuarios con la finalidad de recabar: facturas recibidas y
                emitidas, declaraciones provisionales y definitivas, constancias
                de situación fiscal, opiniones de cumplimiento de obligaciones
                fiscales, adeudos fiscales y buzón tributario;
              </li>
              <li>
                <strong>Insights</strong>. través de indicadores generados a
                partir de datos brutos, permite generar indicadores que ayuden
                al usuario a realizar evaluaciones de riesgo crediticio. No se
                podrá contratar este servicio sin contratar el servicio Connect;
              </li>
              <li>
                <strong>Issuing</strong>. A través de nuestra API, se podrán
                generar facturas (CFDI) con validez fiscal (timbradas) ante el
                SAT de manera simpificada.
              </li>
              <li>
                <strong>Lookup</strong>. Servicio de validación de claves RFC
                contra diferentes bases de datos.
              </li>
            </ol>
          </p>
          <h3>c. Contratación de los Servicios</h3>
          <p>
            Para la contratación de los Servicios, el Usuario podrá hacerlo a
            través de la sección correspondiente dentro de la Plataforma y de
            conformidad con los planes disponibles dentro de la misma. No
            obstante, si para la formalización de acuerdos personalizados, se
            requiriera la formalización de documentos adicionales; entonces la
            contratación de los Servicios se realizará conforme a lo dispuesto
            en la sección "<strong>CONTRATOS ADICIONALES</strong>".
          </p>
          <h3>d. Destino de los Servicios</h3>
          <p>
            Los Servicios podrán ser contratados para uso personal del Usuario o
            para la optimización y/o integración de los servicios y/o ofrecidos
            por el Usuario a sus clientes/usuarios. Es responsabilidad exclusiva
            del Usuario del uso correcto de los Servicios y exclusivamente para
            las finalidades detalladas en los presentes T&C’s, por lo que en
            este acto libera expresamente a SATWS de cualquier responsabilidad
            derivada de este punto.
          </p>
          <h3>e. Credenciales</h3>
          <p>
            El Usuario acepta y reconoce expresamente que para poder utilizar
            los servicios conforme a los presentes T&C’s, deberá ingresar sus
            credenciales y certificados de contribuyente “CIEC”, “e.firma”,
            “CSD” (las “<strong>Credenciales</strong>”) en la Plataforma, las
            cuales serán tratadas de conformidad con los presentes T&C. Si el
            Usuario proporcionara Credenciales de sus clientes/usuarios, el
            Usuario manifiesta bajo protesta de decir verdad que cuenta con las
            autorizaciones/mandatos suficientes y necesarias para ingresar las
            Credenciales en la Plataforma; por lo que en este acto libera a
            SATWS de cualquier responsabilidad derivada de este punto.
          </p>
          <p>
            Por seguridad del Usuario, SATWS no proporciona al Usuario sus
            Contraseñas cuando éste las solicita; no obstante, el Usuario podrá
            solicitar que sus Credenciales sean eliminadas o personalmente
            deberá modificar sus Contraseñas ante el SAT y proporcionarlas
            nuevamente a SATWS. En caso que el Usuario solicite que sus
            Contraseñas sean eliminadas, los Servicios continuarán hasta la
            fecha en la que los mismos deban de concluir de conformidad con los
            presentes T&C’s y sin perjuicio de que los mismos no puedan ser
            proporcionados conforme a lo aquí dispuesto.
          </p>
          <h2>Seguridad de datos</h2>
          <p>
            SATWS cuenta con un sistema de seguridad SSL y algoritmo de cifrado
            AES- 256 para la protección de la información que compartes con
            nosotros. Por lo anterior, nos comprometemos a utilizar tus datos
            únicamente en la medida que lo hayas autorizado y de conformidad con
            lo dispuesto en los presentes T&C’s.
          </p>
          <h2>Contratos adicionales</h2>
          <p>
            Cuando la contratación de los servicios: (i) sea personalizada o
            (ii) requiera la firma de acuerdos adicionales para su
            formalización. Las Partes podrán suscribir contratos adicionales.
          </p>
          <h2>Licencia</h2>
          <p>
            En virtud del presente Contrato, SATWS concede a favor del Usuario,
            una licencia temporal, no exclusiva, revocable y limitada respecto
            de la Plataforma, para que este último la utilice, por sí y para sí
            mismo, de acuerdo a presentes T&C’s, en atención a las
            especificaciones generales y técnicas que se establecen de manera
            enunciativa más no limitativa en la sección siguiente y en el
            Contrato.
          </p>
          <h2>Autenticación</h2>
          <p>
            La Plataforma estará disponible para el Usuario pudiendo estar
            asociado al uso del Dashboard o APIs. El Usuario deberá ingresar a
            la Cuenta de Usuario con:
          </p>
          <p>
            <strong>Dashboard</strong>: (i) su correo electrónico y (ii)
            contraseña proporcionada por el Usuario.
          </p>
          <p>
            <strong>APIs</strong>: a través de la llave de la API generada por
            SATWS.
          </p>
          <p>
            Las funcionalidades disponibles a través de la Cuenta de Usuario
            podrán variar de conformidad con la sección "
            <strong>CONTRATOS ADICIONALES</strong>" y los presentes T&C’s.
          </p>
          <h2>Funcionamiento de la plataforma</h2>
          <p>
            Podrás hacer uso de la Plataforma con la finalidad de consultar
            información sobre los Servicios contratados y hacer uso de los
            mismos, de manera personal. No obstante, el Usuario será el único
            responsable del uso e información que se ingrese en la Plataforma,
            por tanto, en este acto expresamente libera a SATWS de cualquier
            responsabilidad derivada de un mal manejo o uso de la información
            que ingrese en la Plataforma, incluyendo el mal uso o fuga de
            información respecto de los datos personales o las Credenciales,
            obligándose a sacar en paz y a salvo a SATWS de cualquier acción,
            reclamo, juicio o procedimiento de cualquier índole, debiendo el
            Usuario pagar cualquier gasto de defensa que SATWS tenga que erogar
            como consecuencia de tales procedimientos.
          </p>
          <p>
            La información ingresada a la Plataforma, podrá estar disponible
            desde dispositivos electrónicos que cuenten con conexión a Internet.
          </p>
          <p>
            No obstante, lo anterior, queda estrictamente prohibido al Usuario
            permitir que cualquier tercero distinto a él sus empleados, socios,
            accionistas o personal, ingrese y haga uso de la Plataforma. Del
            mismo modo, queda estrictamente prohibido revender el acceso a la
            Plataforma. Cualquier incumplimiento a lo antes establecido será
            considerado como una causal de terminación automática del presente
            Contrato, sin responsabilidad para SATWS y sin necesidad de
            requerimiento judicial o extrajudicial. En este caso, SATWS se
            reserva el derecho de impedir el ingreso o uso del Usuario a la
            Plataforma, así como a eliminar la información que haya sido
            proporcionada por el Usuario.
          </p>
          <p>
            Asimismo, queda estrictamente prohibida cualquier utilización de la
            Plataforma de manera distinta a su uso y destino normal.
          </p>
          <p>
            El Usuario se compromete a informar de manera inmediata a SATWS
            sobre cualquier sospecha de que su contraseña o llave de la API haya
            podido verse comprometida, solicitando a SATWS el cambio de
            contraseña o llave de la API.
          </p>
          <p>
            Asimismo, el Usuario deberá cooperar con SATWS en la investigación
            de incidentes o riesgos de seguridad, así como a ejecutar las
            acciones que se acuerden para la resolución de incidencias y la
            minimización de los riesgos detectados.
          </p>
          <h2>Acuerdo nivel de servicio</h2>
          <p>
            SATWS no garantiza que la Plataforma esté libre de errores, bugs o
            vulnerabilidades; sin embargo, brindará al Usuario, durante la
            vigencia de estos T&C’s, el soporte técnico que sea para que éste
            último pueda utilizar de forma regular la Plataforma, de conformidad
            con lo siguiente:
          </p>
          <ul>
            <li>
              Disponibilidad (“Uptime”). El Usuario podrá consultar en la
              siguiente liga{" "}
              <a href="https://status.sat.ws">https://status.sat.ws</a> las
              métricas de disponibilidad de la Plataforma, así como cualquier
              incidente ocurrido y la solución proporcionada por SATWS. Lo
              anterior, en el entendido de que los incidentes se solucionarán en
              el tiempo que tome a SATWS. No obstante, lo anterior, SATWS
              descontará de la factura por los Servicios mensuales las
              cantidades especificadas de conformidad con el Uptime que se
              indican en la siguiente liga{" "}
              <Link to="/legal/sla">Service Level Agreement (SLA)</Link>; si el
              Usuario hubiere pagado servicios por adelantado; entonces, el
              descuento se tomará como saldo a favor.
            </li>
            <li>
              Soporte (“Mesa de Ayuda”). El Usuario contará con acceso a la Mesa
              de Ayuda dependiendo del plan de soporte contratado por el Usuario
              (el “Plan de Soporte”). Los Planes de Soporte disponibles en la
              Plataforma se encuentran detallados en la sección correspondiente
              dentro de la Plataforma; incluyendo sin limitar: (i) tiempos de
              respuesta de tickets, (ii) niveles de prioridad, y (iii) precios.
              Lo anterior, en el entendido de que SATWS garantiza un tiempo de
              respuesta a los tickets generados por el Usuario de conformidad
              con el Plan de Soporte contratado; más no así el tiempo de
              resolución de incidentes.
            </li>
          </ul>
          <h2>Actualizaciones a la plataforma</h2>
          <p>
            En caso de cualquier actualización a la Plataforma, SATWS se
            compromete a informar al Usuario sobre la fecha aproximada de dicha
            actualización y sus características, para que el Usuario decida
            implementar dicha actualización, así como los costos que pudiera
            ocasionar la misma. Para el caso en que el Usuario decida no
            realizar la actualización, siempre que la misma no sea crítica para
            el funcionamiento de la Plataforma, el Usuario se obliga a
            garantizar la operación de este en condiciones de funcionalidad
            óptima que no afecte a SATWS. Sin perjuicio de lo anterior, cuando
            la actualización de la Plataforma no implique costos adicionales, el
            Usuario se obliga a implementar las mismas tan pronto como la
            actualización se encuentre disponible.
          </p>
          <h2>Condiciones de la licencia</h2>
          <p>
            Las Partes acuerdan que SATWS, se compromete a entregar al Usuario,
            el acceso, para que el Usuario pueda ingresar a la Cuenta de Usuario
            y hacer uso de la Plataforma. Será responsabilidad única y exclusiva
            del Usuario mantener en un lugar seguro tanto su correo electrónico
            y la contraseña para ingresar a la Plataforma.
          </p>
          <p>
            En adición a lo anterior, SATWS se obliga a proporcionar, por sí
            mismo o por medio de terceros debidamente autorizados, la
            configuración, soporte técnico y mantenimiento de la Plataforma, por
            si o por medio de los profesionistas a su servicio, durante la
            vigencia de los presentes T&C’s, a fin de evitar cualquier tipo
            contingencia que pudiera experimentar el Usuario, o bien, corregir
            los errores que de tiempo en tiempo pueden presentar en la
            Plataforma. En adición, queda expresa y estrictamente prohibido al
            Usuario realizar por sí mismo o a través de cualquier tercero ajeno
            a SATWS, cualquier tipo de configuración o mantenimiento relacionado
            con la Plataforma objeto del presente Contrato, siendo la violación
            a esta obligación una causal de terminación inmediata del presente
            Instrumento y sin necesidad de requerimiento judicial o
            extrajudicial.
          </p>
          <h2>Garantías</h2>
          <p>
            SATWS procurará el buen y debido funcionamiento de la Plataforma
            siempre y cuando se conduzca sobre los parámetros, capacidad
            operativa y sistemas de cómputo debidos, incluyendo la adecuada
            conexión a Internet. En tal virtud, las Partes expresamente acuerdan
            que cualquier mal funcionamiento de la Plataforma o cualquier falla
            generada por causas directamente imputables al Usuario, a los
            proveedores de servicios de Internet o al SAT, no serán
            responsabilidad de SATWS y, por tanto, SATWS no será en ningún
            momento responsable y los Servicios continuarán hasta que el
            Usuario, los proveedores de servicios de Internet o el SAT reparen
            sus fallas.
          </p>
          <h2>Propiedad de la plataforma</h2>
          <p>
            SATWS hace constar que es el único y exclusivo titular de los
            derechos patrimoniales sobre la Plataforma. Asimismo, garantiza la
            originalidad y buen funcionamiento de la Plataforma, por lo que, en
            este acto, SATWS se obliga a sacar en paz y a salvo al Usuario ante
            cualquier reclamación sobre la titularidad y originalidad de la
            Plataforma.
          </p>
          <p>
            Sin perjuicio de lo anterior, SATWS no garantiza que la Plataforma
            esté libre de errores, bugs o vulnerabilidades; sin embargo,
            brindará de forma gratuita al Usuario, durante la vigencia de este
            Contrato, el soporte técnico que sea necesario para que este último
            pueda utilizar de forma regular la Plataforma.
          </p>
          <h2>Soporte técnico</h2>
          <p>
            SATWS se compromete a brindar el soporte técnico y asesoría al
            Usuario que se detalla en la sección correspondiente dentro de la
            Plataforma. El Usuario deberá seleccionar el plan de soporte que
            mejor se adapte a sus necesidades y se compromete a cubrir sus
            costos conforme al plan de soporte seleccionado.
          </p>
          <h2>Propiedad intelectual</h2>
          <p>
            Las Partes reconocen que todos los derechos de propiedad intelectual
            que corresponden a cada una de ellas son de exclusiva titularidad de
            éstas y seguirán correspondiendo a las mismas. Por lo que nada de lo
            establecido en estos T&C’s genera a favor de cualquiera de las
            Partes el derecho de utilizar o explotar la propiedad intelectual de
            la otra Parte, salvo lo expresamente previsto en el presente. En
            este sentido, queda expresamente entendido entre las Partes, que la
            Plataforma se concede bajo una licencia de uso, no exclusiva y
            temporal.
          </p>
          <p>
            La Plataforma se encuentra protegida por las leyes mexicanas de
            derechos de autor y por los tratados internacionales de los que
            México sea parte. Por consiguiente, se deberá tratar a los productos
            y servicios como cualquier otro material sujeto a derechos de autor.
            Asimismo, queda expresamente entendido entre las partes que la
            Plataforma no podrá ser alquilada, arrendada o prestada o
            transmitida de manera alguna sin autorización expresa del titular de
            los derechos sobre la Plataforma.
          </p>
          <h2>Prohibiciones al usuario</h2>
          <p>
            Está estrictamente prohibido reproducir, modificar, alterar,
            distribuir cualquier copia, comunicar públicamente, transformar,
            mutilar, realizar cambios o cualquier tipo de uso o explotación de
            la Plataforma distinta al permitido por medio de estos T&C’s, de
            manera total o parcial, por cualquier medio conocido o por conocerse
            en el futuro. Asimismo, queda estrictamente prohibido realizar
            cualquier proceso de ingeniería inversa con el propósito de usar o
            alterar cualquiera de los módulos y/o el código fuente y código
            objeto de la Plataforma, sin la previa autorización por escrito de
            SATWS.
          </p>
          <p>
            Todos los nombres, logotipos y marcas son propiedad de SATWS, sus
            clientes o proveedores de contenido y ninguno de estos elementos
            podrá ser utilizado para propósito alguno sin una autorización,
            expresa y por escrito del titular de dichos signos distintivos. El
            uso no autorizado de dichos elementos será sancionado de conformidad
            con la legislación aplicable. Asimismo, todo el material contenido
            en la Plataforma, incluyendo mas no limitando a: diseños, dibujos,
            programas de cómputo (código fuente y código objeto), bases de
            datos, material gráfico, audiovisual, fotográfico, textos,
            invenciones, modelos, patentes, entre otros derechos de Propiedad
            Intelectual son propiedad única y exclusiva de SATWS y se encuentran
            protegidos por la Ley Federal del Derecho de Autor, la Ley de la
            Propiedad Industrial, así como por las leyes en materia de Propiedad
            Intelectual de otros países y los Tratados Internacionales en la
            materia de los que México sea parte.
          </p>
          <h2>Vigencia</h2>
          <p>
            Los Presentes T&C’s iniciarán su vigencia en la fecha de aceptación
            de los mismos y continuarán vigentes mientras exista una relación
            entre las Partes.
          </p>
          <h2>Tarifas, forma de pago y facturación</h2>
          <p>
            Como contraprestación por los Servicios, el Usuario se obliga a
            pagar a SATWS las cantidades correspondientes según los Servicios
            contratados en el correspondiente plan, cotización o contrato. El
            pago será realizado, ya sea a través de transferencia electrónica de
            fondos inmediatamente disponibles o cualquier medio de pago
            electrónico disponible en la Plataforma, en las fechas acordadas
            previamente por las Partes.
          </p>
          <p>
            SATWS hará llegar al Usuario la factura correspondiente dentro del
            primer día cada mes, y el Usuario se obliga a pagar las cantidades
            devengadas durante el mes que corresponda, dentro de los 5 (Cinco)
            días naturales siguientes a la recepción de la factura que
            corresponda, a través de transferencia electrónica de fondos
            inmediatamente disponibles al número de cuenta e institución
            bancaria que el SATWS le indique o a mediante cargo a la cuenta
            proporcionada por el Usuario.
          </p>
          <h2>Cesión de derechos</h2>
          <p>
            El Usuario no podrá ceder total o parcialmente los derechos y las
            obligaciones derivadas de los presentes T&C’s, sin el previo
            consentimiento por escrito del SATWS. Por su parte, SATWS podrá
            ceder total o parcialmente los derechos y obligaciones de los
            presentes T&C’s, bastando una simple notificación al Usuario sobre
            tal circunstancia.El Usuario no podrá ceder total o parcialmente los
            derechos y las obligaciones derivadas de los presentes T&C’s, sin el
            previo consentimiento por escrito del SATWS. Por su parte, SATWS
            podrá ceder total o parcialmente los derechos y obligaciones de los
            presentes T&C’s, bastando una simple notificación al Usuario sobre
            tal circunstancia.
          </p>
          <h2>Relación entre los contratantes</h2>
          <p>
            En virtud de los presentes T&C’s no se crea una relación de mandante
            y mandatario, agente, comitente y comisionista, patrón/patrón
            sustituto y empleado, socio y asociado entre el Usuario y SATWS.
            Ninguna de las Partes estará facultada para representar y obligar a
            la otra de manera alguna, y cada una de las Partes será responsable
            exclusivamente de sus propios actos, salvo lo dispuesto en la
            sección PROCESO DE ELEGIBILIDAD PARA LA OBTENCIÓN DE SALDOS y lo
            estipulado en el Contrato.
          </p>
          <h2>Información confidencial</h2>
          <p>
            Significa cualquier información propiedad de SATWS y/o sus filiales,
            subsidiarias y/o clientes de ésta divulgadas al Usuario, en
            cualquier forma y por cualquier medio, de manera enunciativa más no
            limitativa, de forma verbal, electrónica, visual, por escrito o en
            cualquier otra forma tangible, incluyendo sin limitar las ideas,
            fórmulas, bases de datos, normas, artículos, estudios, folletos,
            publicaciones, manuales, sistemas, procedimientos, informes,
            reportes técnicos, minutas, know how, secretos industriales,
            patentes, derechos de autor, software, licencias, datos,
            invenciones, algoritmos, técnicas, procesos, planes y proyectos de
            mercadotecnia, publicidad, estrategias, pronósticos, información
            confidencial de terceros, listas de clientes, de contactos, de
            proveedores, planes de negocios, análisis de mercado, conocimiento
            de mercado o cualquier otra información técnica, económica, de
            negocios o de cualquier otra índole que sea propiedad de SATWS, de
            sus filiales, subsidiarias y/o clientes o potenciales clientes así
            como toda información relativa o relacionada con las operaciones,
            desarrollo de negocios, desarrollo de productos, desarrollo de
            nuevos proyectos, finanzas, mercadotecnia, publicidad, promociones
            comerciales, logística, operaciones de canje promocionales o toda
            aquella información cuya divulgación y exposición, cualquiera que
            sea su forma, perjudique de forma alguna a SATWS y/o a sus filiales,
            subsidiarias y/o a sus clientes (la “
            <strong>Información Confidencial</strong>”).
          </p>
          <p>
            El Usuario se obliga a no apoderarse, usar o explotar, por sí o por
            terceros, directa o indirectamente, para beneficio propio o de
            terceros y a no divulgar o revelar a terceros la Información
            Confidencial de SATWS o de sus clientes, a la cual haya tenido,
            tenga o vaya a tener acceso en el futuro. Asimismo, el Usuario se
            obliga a tomar todas las medidas razonables para evitar cualquier
            difusión prohibida y/o uso indebido de la Información Confidencial.
          </p>
          <p>
            El Usuario reconoce expresamente que la Información Confidencial que
            SATWS le ha proporcionado o que le proporcione a futuro, puede
            constituir además un Secreto Industrial, en los términos del
            artículo 82 de la Ley de la Propiedad Industrial, por lo que se
            obliga a no revelar o difundir dicho Secreto Industrial, por
            cualquier forma o medio y a regirse respecto del mismo, por el
            Título Tercero de la Ley de la Propiedad Industrial. Lo anterior,
            sin perjuicio de las sanciones civiles y/o penales que correspondan
            por violación a los Derechos de Propiedad Intelectual, incluyendo el
            delito de revelación de secretos, contemplado en los artículos 210,
            211, 211 bis y demás relativos del Código Penal Federal. Asimismo,
            el Usuario reconoce que la Información Confidencial proporcionada
            (pasada, presente y futura) por SATWS es y seguirá siendo propiedad
            de ésta última, por lo cual el Usuario no adquiere ningún tipo de
            derecho, licencia, propiedad o interés respecto de la misma, para
            copiarla, venderla, usarla o disponer de ella de forma alguna.
          </p>
          <p>
            El Usuario será el único responsable de asegurar y mantener la
            confidencialidad y seguridad de la clave personal que utiliza para
            su cuenta de Usuario de la plataforma de SATWS. El Usuario acepta no
            revelar su clave personal a terceros y será el único responsable por
            cualquier uso o acción derivada de la utilización de dicha clave en
            la plataforma de SATWS. Asimismo, el Usuario se compromete a
            notificar inmediatamente SATWS para el caso en que su clave personal
            se haya perdido o fuera robada.
          </p>
          <h2>Ideas no solicitadas</h2>
          <p>
            SATWS no busca ni acepta ideas no solicitadas, sugerencias o
            materiales relacionados, entre otros, al desarrollo, elaboración o
            mercadeo o proceso de nuestros productos y servicios. Al aceptar
            estos Términos se evita cualquier malentendido sobre propiedad
            intelectual con personas del público que envían comentarios o ideas
            referentes a los productos, servicios o a los conceptos que SATWS
            desarrolla, por tanto, cualquier idea no solicitada podrá utilizarse
            o no por SATWS sin generar algún tipo de relación con la persona que
            envía dicha idea.
          </p>
          <h2>Divulgación y uso de tus mensajes y comunicados</h2>
          <p>
            Todos los comunicados y otros materiales (incluyendo, sin
            limitación, ideas no solicitadas, sugerencias o materiales) que se
            envíen a la Plataforma o a SATWS, por cualquier medio, se
            convertirán en propiedad única y exclusiva de SATWS, y podrán ser
            utilizados por ésta última para cualquier propósito, inclusive
            comercial, sin compensación alguna.
          </p>
          <h2>Disponibilidad de la plataforma</h2>
          <p>
            SATWS podrá bloquear, interrumpir o restringir el uso y acceso a la
            Plataforma cuando lo considere necesario, así como eliminar o dar de
            baja la Plataforma o cualquiera de las partes que la componen. El
            Usuario tendrá en todo momento el derecho de dar su Perfil de baja
            de la Plataforma.
          </p>
          <h2>Incumplimiento</h2>
          <p>
            Para el caso de incumplimiento de cualquiera de las obligaciones de
            los presentes Términos y Condiciones, así como de la legislación
            aplicable, SATWS podrá suspender el acceso a Plataforma o eliminar
            el Perfil del Usuario; sin perjuicio de las acciones legales
            disponibles en favor de SATWS para el caso en que alguna conducta
            del Usuario le genere un daño o perjuicio.
          </p>
          <p>
            El Usuario se compromete a sacar en paz y a salvo a SATWS por
            cualquier reclamación, denuncia, demanda, querella o procedimiento
            de cualquier índole derivado de su incumplimiento, debiendo resarcir
            a SATWS todos los gastos erogados para su defensa, incluyendo pero
            sin limitar a los honorarios de abogados, así como a cualquier tipo
            de indemnización pagada por SATWS.
          </p>
          <h2>Jurisdicción</h2>
          <p>
            La Plataforma estará disponible en línea con independencia de que la
            mismas pueda no estar disponibles en el país donde el Usuario se
            encuentre. Esta Plataforma está controlada y operada por SATWS desde
            México y SATWS no garantiza, explícita o implícitamente, que la
            información y el material contenidos en la Plataforma, incluyendo
            sin limitación la información y otros materiales que promueven las
            actividades comerciales, productos o servicios aquí descritos, sean
            apropiados o estén disponibles en otras localidades. Adicionalmente,
            algún software de la Plataforma puede estar sujeto a normas de
            exportación impuestas por el Gobierno de México por lo que no puede
            ser descargado y/o copiado, o bien exportado o re-exportado, al
            interior de (o a un ciudadano o residente de) un país que se
            encuentre bajo embargo del gobierno Mexicano o en el cual se
            encuentre restringida la importación o descarga de dicho software
            (en adelante “País Restringido”).
          </p>
          <p>
            Si el Usuario descarga o usa la Plataforma, establece y garantiza
            que no es ciudadano, que no reside dentro o se encuentra en
            territorio controlado por algún País Restringido. Las leyes
            mexicanas regirán estos T&C’s por lo cual, acepta expresamente
            renunciar a cualquier jurisdicción que pudiera corresponderle por
            razón de su domicilio, presente o futuro, sometiéndose expresamente
            a la jurisdicción y competencia de los tribunales de la Ciudad de
            México, respecto de todos los asuntos relacionados con estos T&C’s.
          </p>
          <h2>Renuncia a garantias</h2>
          <p>
            SATWS no establece representación o garantía alguna sobre la
            exactitud, confiabilidad o integridad del contenido de la
            Plataforma. Dentro de las limitantes establecidas por la ley, SATWS
            renuncia a toda garantía, explícita o implícita, incluyendo sin
            limitación las garantías implícitas sobre disponibilidad y aptitud
            para un propósito determinado. Más aún, SATWS no garantiza que la
            Plataforma o el servidor que la hace disponible estén libres de
            vulnerabilidades o brechas de seguridad no conocidas por SATWS, por
            lo que el uso del sitio lo hacen los Usuarios bajo su propia
            responsabilidad y riesgo.
          </p>
          <p>
            SATWS no será responsable por daños de cualquier tipo, incluyendo
            sin limitación daños especiales o consecuenciales, derivados del
            acceso o imposibilidad de acceso a la Plataforma, así como de su uso
            o dependencia o de la información contenida en la Plataforma.
          </p>
          <h2>Otros</h2>
          <p>
            SATWS, a su discreción, se reserva el derecho de: (i) cambiar estos
            T&C’s, (ii) dar seguimiento y eliminar información proporcionada por
            los visitantes a la Plataforma y (iii) limitar o eliminar la
            disponibilidad de la Plataforma, en cualquier momento, sin previo
            aviso a entera discreción de SATWS. Si se llegara a determinar que
            algún término, condición o disposición de estos Términos y
            Condiciones es ilegal, inválida, nula o por cualquier razón,
            inaplicable, la validez y aplicación de las demás disposiciones no
            será de ninguna manera afectada o deteriorada. Este instrumento
            constituye un acuerdo completo entre el Usuario y SATWS.
          </p>
        </Wrapper>
      </div>
    </Layout>
  )
}
